

.inner-container{
    width: 95%;
    max-width: 1300px;
    margin: auto;
}

.inner-container-welocome{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
}

.welcome-image{
    flex-basis: 40%;
}

.welcome-desc{
    flex-basis: 60%;
    width: 100%;
    height: 100%;
}

.welcome-image{
    display: flex;
    justify-content: space-between;
    align-items:center;
    gap: 25px;
    height: 84vh;
    position: relative;
    /* background: gray; */
}

.welcome-image img{
    object-fit: contain;
    height: auto;
    width: auto;
}

.image-01-welcome img{
   margin-top: 60px;
}

.image-02-welcome img{
    height: auto;
    width: auto;
}

.welcome-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    background: #FE5D14;
    padding: 38px;
    margin-top: 25px;
    width: calc(100%+100px);
    margin-left: -100px;
}

.welcome-icon svg{
    color: #fff;
}

.welcome-icon h6{
    color: rgba(255, 255, 255, 0.60);
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 0 5px;
}

.welcome-icon h2{
    margin: 0;
    color: #ffffff;
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
}

.sub-title-wel {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
}

.sub-title-wel  .border-left {
    position: relative;
    display: block;
    top: -1px;
    background-color: #FE5D14;
    width: 20px;
    height: 2px;
}

.sub-title-wel h6{
  color: #FE5D14;
  font-size: 14px;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin: 0;
}

.welcome-desc h1{
    font-size: 40px;
    max-width: 90%;
    margin: 12px 0px;
    
}

.wel-buttons{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
    gap: 15px;
}

.each-wel-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 15px;
    border: 1px solid #dddddd;
    border-radius: 8px;
    padding: 25px 24px 25px;
    position: relative;
    z-index: 10;
    width: 100%;
    flex-basis: 50%;
}

.each-wel-btn svg{
    z-index: 2;
    color: #FE5D14;
    transition: -webkit-transform 0.4s ease;
    transition: transform 0.4s ease;
    transition: transform 0.4s ease, -webkit-transform 0.4s ease;
}

.each-wel-btn h2{
    font-size: 20px;
    margin: 0px 0px;
    font-weight: 700;
    color: #111e37;
    transition: -webkit-transform 0.4s ease;
    transition: transform 0.4s ease;
    transition: transform 0.4s ease, -webkit-transform 0.4s ease;
}

.welcome-desc h3{
    font-size: 22px;
    line-height: 30px;
    font-weight: 600;
    margin: 0 0 25px;
    color: #111e37;
    margin-top: 50px;
}

.welcome-desc p{
    color: #616161;
    font-size: 17px;
    font-weight: 500;
    margin-top: 10px;
}

.each-wel-btn::after {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    border-radius: 8px;
    background-color: #FE5D14;
    transition: -webkit-transform 0.4s ease;
    transition: transform 0.4s ease;
    transition: transform 0.4s ease, -webkit-transform 0.4s ease;
    transform-origin: left right;
    -webkit-transform: scale(1, 0);
    transform: scale(1, 0);
    z-index: -10;
  }

  .each-wel-btn:hover::after {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    transform-origin: bottom center;
  
  }

  .wel-btn{
    margin-top: 30px;
  }

  .each-wel-btn:hover h2 {
    color: #fff;
    transition: -webkit-transform 0.4s ease;
    transition: transform 0.4s ease;
    transition: transform 0.4s ease, -webkit-transform 0.4s ease;
    }

.each-wel-btn:hover svg {
    color: #fff;
    transition: -webkit-transform 0.4s ease;
    transition: transform 0.4s ease;
    transition: transform 0.4s ease, -webkit-transform 0.4s ease;
}

@media screen and (min-width:200px) and (max-width:1200px){
    .inner-container-welocome{
       flex-direction: column;
    }
}

@media screen and (min-width:200px) and (max-width:800px){
    .image-02-welcome img{
        display: none;
    }

    .welcome-icon{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        padding: 38px 12px;
        margin-top: -40px;
        width: calc(80%);
        margin: -80px auto;
        margin-left: auto;
    }

    .welcome-image{
        display: block;
        width: 100%;
    }


    .image-01-welcome{
        flex-basis: 100%;
        width: 100%;

    }
    .image-01-welcome img{
        margin-top: 0px;
        width: 100%;
        height: 80vh;
        object-fit: cover;
     }

     .welcome-desc{
        margin-top: 90px;
     }
}