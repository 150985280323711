.Conatiner{
    width: 100%;
    height: fit-content;
    position: relative;
    margin: 130px 0px;
}


.inner-container{
    width: 95%;
    max-width: 1110px;
    margin: auto;
}

.inner-container-quality{
    grid-column-gap: 4rem;
    grid-row-gap: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(250px,1fr));
}


.quality{
    position: relative;
    width: 90%;
    margin: auto;
}

.ineer-quality{
    position: relative;
    background: #fff;
    height: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    border-radius: 7px;
    overflow: hidden;
}

.quality-design{
    position: absolute;
    width: 130%;
    height: 50%;
    background: #FFF3ED;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 7px;
}

.quality-cont{
    padding: 10px 25px;
    height: 190px;
 
}

.quality-cont p {
    color: #61657E;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
  
}

.quality-cont h3{
    font-size: 17px;
    font-family: 'Poppins', sans-serif;
}

.feature-icon{
    width: 45px;
    height: 45px;
    border-radius: 50%;
    font-size: 40px;
    background: #FFDAC9;
    color: #FE5D14;
    position: absolute;
    bottom: -50px;
    right: -48px;
    padding: 40px 40px;
    line-height: 0;
    transition: .3s linear;
    z-index: 10;
}

.feature-icon svg{
    position: absolute;
    top: 25px;
    left: 30px;
    font-size: 32px;
}



.feature-icon-design {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    font-size: 40px;
    background: #FFF3ED;
    position: absolute;
    bottom: -52px;
    right: -49px;
    padding: 40px 40px;
    line-height: 0;
    transition: .3s linear;
    z-index: 5;
}

.ineer-quality:hover .feature-icon{
    background: #FE5D14;
    color: #fff;
    
}

@media screen and (min-width:200px) and (max-width:750px){
    .quality-design{
        width: 120%;
    }
    .Conatiner{
        margin: 150px 0px 100px;
    }
    
    
}

/* .dot-image{
    position: absolute;
    right: 2%;
    bottom: -65%;
    opacity: 0.2;
    width: 200px;
} */