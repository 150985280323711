.footer-conatiner{
    background-image: url("../../assets/images/footer_bg.jpg");
    background-size: cover;
    background-position: center;
    height: fit-content;
    padding: 50px 0;
    overflow: hidden;
    height: fit-content;
    width: 100%;
    position: relative;
}

.inner-footer{
    max-width: 1200px;
}

.inner-container-footer{
    width: 100%;
    grid-column-gap: 3rem;
    grid-row-gap: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(250px,1fr));
}

.col h3{
    color: #fff;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 28px;
}

.col p{
    color: #ced1de;
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
}


.col .list a{
    color: #ced1de;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.2rem;
    cursor: pointer;
    transition: all 0.2s linear;
}

.col .list p a:hover{
    color: #FFDAC9;
    transition: all 0.2s linear;
}

.col .image-container{
    grid-column-gap: 0.5rem;
    grid-row-gap: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(80px,1fr));
}

.col .image-container div{
    overflow: hidden;
    height: 100px;
}


.col .image-container div image{
    object-fit: cover;
    height: fit-content;
    width: 100%;
}

.company-details{
    width: 100%;
    margin: auto;
    border-radius: 8px;
    background: #131944;
    margin: 50px 0px 10px;
   
}

.company-details .inner-cd{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
    gap: 3rem;
    width: 100%;
    max-width: 97%;
    margin: auto;
}

.logo{
    flex-basis: 25%;
}

.number-container{
    flex-basis: 40%;
}

.social{
    flex-basis: 40%;
}

.cd-col img{
    width: 150px;
    object-fit: contain;
}

.number-col{
    text-decoration: none;
}

.cd-col .number-container{
    background: #FE5D14;
    border-radius: 8px;
}

.cd-col .number-container .number-inner-container{
    width: 100%;
    max-width: 97%;
    margin: auto;
    padding: 10px 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
}

.number-col svg{
    color: #131944;
}

.number-inner-container .logo{
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    flex-basis: 20%;
}

.number-inner-container .num{
    flex-basis: 80%;
}

.number-inner-container .logo svg{
    font-size: 60px;
    padding: 10px;
   
}

.number-inner-container p{
    color: #fff;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    margin: 2px 0px;
}

.number-inner-container .num-main{
    font-weight: 700;
    font-size: 20px;
}

.social{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.social .follow-us{
    display: flex;
    align-items: center;
    gap: 2rem;
}

.social .all-links{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.all-links .each-link{
    width: 45px;
    height: 45px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.all-links .each-link svg{
    font-size: 18px;
    color: #FE5D14;
}

.follow-us{
    color: #fff;
    font-weight: 500;
    font-size: 18px;
}

@media screen and (min-width:200px) and (max-width:1000px){
    .company-details .inner-cd{
        flex-direction: column;
    }
    .logo{
        flex-basis: 100%;
    }
    
    .number-col{
        flex-basis: 100%;
        width: 100%;
    }
    
    .social{
        flex-basis: 100%;
    }
    .cd-col .number-container{
        width: 100%;
    }

    .number-inner-container .logo{
        flex: 20%;
    }
}

@media screen and (min-width:200px) and (max-width:380px){
    .social .follow-us{
       flex-direction: column;
       gap: 0rem;
    }
    
}

.copyright{
    background: #131944;
    padding: 15px 0px;
    text-align: center;
}

.copyright p{
    color: #fff;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    margin: 2px 0px;
    font-size: 14px;
}

.copyright p a{
    color: #FE5D14;
}