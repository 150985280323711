.slider{
    position: fixed;
    top: 0;
    right: -500px;
    bottom: 0;
    width: 100%;
    max-width: 300px;
    background: #fff;
    z-index: 70;
    transition: all linear 500ms;
    overflow-y: scroll;
}

.slider-active{
    right: 0;
    transition: all linear 500ms;
}

.img-container{
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 95%;
    margin: auto;
}

.slider-inner-conatiner img{
    width: 100px;
    object-fit: contain;
    padding: 15px 0px;
}

.all-slider-links{
    margin-top: 130px;
    display: block;
}

.all-slider-links p{
    list-style: none;
    padding: 18px 12px;
    border-bottom:1px solid rgb(0 0 0 / 10%);;
    margin: 0;
}




.all-slider-links p:first-child{
    border-top:1px solid rgb(0 0 0 / 10%);;
}

.all-slider-links p a{
    position: relative;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    display: block;
    line-height: 1;
    position: relative;
    z-index: 1;
    transition: all 0.3s linear;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.slider-product{
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    line-height: 1;
    position: relative;
    z-index: 1;
    transition: all 0.3s linear;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    cursor: pointer;
}

.slider-product .sub-span{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.all-slider-links .page-active span{
    color: #FE5D14;
}

.all-slider-links .page-active svg{
    color: #FE5D14;
}

.all-slider-links p a span{
    color: #0C1239;
}

.all-slider-links p a svg{
    color: #0C1239;
}

.all-slider-links p  span{
    color: #0C1239;
}

.all-slider-links p  svg{
    color: #0C1239;
}

.all-sl-link:hover span{
    color: #FE5D14;
    transition: all 0.3s linear;
}

.all-sl-link:hover svg{
    color: #FE5D14;
    transition: all 0.3s linear;
}

.slider-product .sub-span:hover span{
    color: #FE5D14;
    transition: all 0.3s linear;
}

.slider-product .sub-span:hover svg{
    color: #FE5D14;
    transition: all 0.3s linear;
}



.slider-social{
    display: flex;
    justify-content: center;
    gap: 12px;
    margin: 22px 0px;
    gap: 5px;
}

.slider-social a{
    border: 1px solid rgb(0 0 0 / 10%);;
    padding: 10px 12px;
    color: #0C1239;
    background: transparent;
    transition: all 0.3s linear;
}

.slider-social a:hover{
    color: #fff;
    background: #FE5D14;
    transition: all 0.3s linear;
}

.produt-sub-link{
    color: #0C1239;
    transition: 400ms ease-in-out;
    overflow-y: scroll;
    height: 0px;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0 );
    width: 100%;
    transition: all 0.4s ease-in-out;
}

.produt-sub-link h6{
    width: 100%;
    font-size: 13px;
    font-weight: 600;
    margin: 0;
    padding: 5px 0px;
}

.produt-sub-link h6 span{
    display: flex;
    justify-content: flex-start;
    gap: 4px;
}

.produt-sub-link.active{
    margin-top: 12px;
    height: fit-content;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100% );
    transition: all 0.4s ease-in-out;
    overflow: hidden;
}

.produt-sub-link h6 a{
   display: flex;
   justify-content: flex-start;
   align-items: center;
   gap: 4px;
   font-size: 13px;
}

.roofing-slider{
    padding: 0px 10px;
    height: 0px;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0 );
    transition: all 0.4s ease-in-out;
    margin-top: 10px;
}

.roofing-slider.active{
    height: fit-content;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100% );
    transition: all 0.4s ease-in-out;
}

.roofing-slider h6{
    margin: 0px;
    padding: 10px 0px;
}

.produt-sub-link svg{
    transition: all 0.4s ease-in-out;
}

.svg-active{
    transition: all 0.4s ease-in-out;
    transform: rotate(90deg);
}