.trust-container{
    position: relative;
    background-image: linear-gradient(rgba(21, 21, 24, 0.8),rgba(21, 21, 24, 0.8)) , url('../../assets/images/trust01.jpg') ;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    overflow: hidden;
}

.inner-conatiner-trust{
    padding: 100px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
}

.video-tittle{
    flex-basis: 60%;
}

.trusting{
    position: relative;
    flex-basis: 40%;
    z-index: 3;
}


.whatsapp-trust{
    display: inline-block;
	position:relative;
    width: 60px;
    height: 60px;
	background-color:#fbba9b;
	color:#FFF;
    border-radius:50px;
	text-align:center;
    z-index: 3;
}

.whatsapp-trust svg{
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-50% , -50%);
    font-size: 40px;
}



.trust-company-shape-1 {
    position: absolute;
    top: -103px;
    left: 120px;
    width: 5px;
    height: 600px;
    background-color: var(--roofsie-base, #FE5D14);
    z-index: 0;
    transform: rotate(45deg);
}

.trust-company-shape-2{
    position: absolute;
    top: -103px;
    left: 320px;
    width: 5px;
    height: 370px;
    background-color: var(--roofsie-base, #FE5D14);
    z-index: 0;
    transform: rotate(45deg);
}

.trust-company-shape-3{
    position: absolute;
    top: -184px;
    left: 310px;
    width: 105px;
    height: 812px;
    background-image: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, white 100%);
    transform: rotate(45deg);
    mix-blend-mode: soft-light;
    z-index: 2;
}

.video-tittle h1{
    position: relative;
    font-family: 'Poppins', sans-serif;
    color: #fff;
    z-index: 3;
    font-size: 40px;
    margin: 10px 0px;
}

.trusting p{
    position: relative;
    list-style: none;
    font-family: 'Poppins', sans-serif;
    margin: 8px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    font-size: 14px;
    padding: 15px 30px 15px;
    z-index: 10;
    cursor: pointer;
}

.trusting p span{
    color: #fff;
    z-index: 1;
}

.trusting p svg{
    font-size: 22px;
    color: #FE5D14;
    font-size: 500;
}

.trusting p::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: "";
    background-color: rgba(0, 0, 0, 0.2);
    transition: all 500ms ease;
    z-index: 0;
    transition: all  500ms;
}

.trusting  p:hover::before {
    background-color: rgba(255, 255, 255, 0.1);
    transition: all  500ms;
}

@media screen and (min-width:200px) and (max-width:900px){
    .video-tittle{
        flex-basis: 45%;
    }
    
    .trusting{
        flex-basis: 55%;
    }
    .video-tittle h1{
        font-size: 20px;
    }
    .trust-company-shape-1 {
        top: -103px;
        left: 0px;
        width: 5px;
        height: 600px;
    }
    
    .trust-company-shape-2{
        top: -103px;
        left: 180px;
        width: 5px;
        height: 370px;
    }
    
    .trust-company-shape-3{
        top: -184px;
        left: 120px;
        width: 105px;
        height: 812px;
    }
}

@media screen and (min-width:200px) and (max-width:600px){
    .inner-conatiner-trust{
        flex-direction: column;
    }
    .video-tittle{
        flex-basis: 50%;
        width: 100%;
    }
    
    .trusting{
        flex-basis: 50%;
        width: 100%;
    }
    .video-tittle h1{
        font-size: 30px;
    }
}


@media screen and (min-width:200px) and (max-width:400px){
    .video-tittle h1{
        font-size: 25px;
    }
}