.banner-container-cu{
    position: relative;
    height: fit-content;
    position: relative;
}

.banner-main-container-cu{
    position: relative;
    background-image: url('../../../../assets/images/contct-banner.jpg');
    position: relative;
    background-size: cover;
    background-position: center;
    z-index: 1;
    height: 40vh;
}

.banner-inner-conatiner-cu{
    position: relative;
    top: 50%;
    transform: translateY(-50%); 
    display: flex;
    align-items: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3rem;
    width: 95%;
    max-width: 1120px;
    margin: auto;
}

.Banner-cu-cont h2 , .Banner-cu-cont p{
    margin: 5px 0px;
    color: #fFf;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
}

.Banner-cu-cont p span{
    color: #FE5D14;
}