.Nav-menu{
    display: none;
}

nav{
    position: relative;
    width: 100%;
    background: #FFFFFF;
    transition: all .3s ease-in-out;
}

.nav-white{
    position: fixed;
    top: 0;
    z-index: 70;
    transition: all .3s ease-in-out;
    box-shadow: 0 0 40px 5px rgb(0 0 0 / 8%);
    animation: navAnimate 0.5s ease-in-out;
}

@keyframes navAnimate {
    0%{
        transform: translateY(-50px);
    }100%{
        transform: translateY(0px);
    }
}

nav .nav-container{
    width: 95%;
    margin: auto;
    height: fit-content;
    transition: all 0.2s ease-in-out;
    max-width: 1300px;
}

nav .nav-inner-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5rem;
    padding: 10px 0px;
    width: 100%;
    margin: auto;
}

.image-container{
    flex-basis: 2cm;
}

.navlink-parent-container{
    flex-basis: 80%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
}

.navlink-container{
    flex-basis: 60%;
}

.button-containe{
    flex-basis: 40%;
}

.button-container{
    flex-basis: 15%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.image-container img{
    width: 130px;
    object-fit: contain;
}

.nav-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-container ul{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-container ul li{
    list-style: none;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
    color: #0C1239;
    font-size: 14px;
    font-weight: 600;
}

.nav-container ul li a{
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
    color: #0C1239;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    display: flex;
    line-height: 1;
    position: relative;
    z-index: 1;
    transition: all 0.3s linear;
}

.nav-container ul li a:hover{
    color: #FE5D14;
    transition: all 0.3s linear;
}

.nav-container ul li a.active{
    color: #FE5D14;
}

.nav-container ul li:hover{
    color: #FE5D14;
}


 .button{
    position: relative;
    border: none;
    outline: none;
    border-radius: 4px;
    padding: 12px 20px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    background: #FE5D14;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    color: #fff;
    font-size: 600;
    font-size: 15px;
    text-decoration: none;
}

.button a{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    color: #fff;
    font-size: 600;
    font-size: 15px;
    text-decoration: none;
}

.button::before{
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0%;
    background: #0C1239;
    transition: 0.8s;
    border-radius: 50% 50% 0% 0%;
}

.button:hover::before{
    height:180%;
    transition:0.8s ;
    border-radius: 4px;
}

.button span , .button svg{
    z-index: 1;
}


@media screen and (min-width:200px) and (max-width:1200px){
    .button-container{
        display: none;
    }
    .image-container{
        flex-basis: 20%;
    }
    
    .navlink-container{
        flex-basis: 80%;
    }
    
}

@media screen and (min-width:200px) and (max-width:750px){
    .navlink-container{
        display: none;
    }
    .Nav-menu{
        display: block;
    }
    nav{
        z-index: unset;
    }
    
}

.Nav-menu{
    position: relative;
    z-index: 80;
}

.burger{
    padding: 4px 8px;
    cursor: pointer;
    border-radius: 3px;
}

.burger div{
    width: 25px;
    height: 3px;
    background-color: #FE5D14;
    margin: 5px 0px;
    transition: all .3s linear ;
}

.toggle .line1{
    transform: rotate(-45deg )  translate(-5px,6px);
    transition: all .3s linear ;
}

.toggle .line2{
        opacity: 0;
        transition: all .3s linear ;
}

.toggle .line3{
    transform: rotate(45deg)  translate(-5px,-6px);
    transition: all .3s linear ;
}

.product-li{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.product-dropdown{
    position: absolute;
    top:55px;
    left: 50%;
    transform: translateX(-50%);
    padding: 25px 0px;
    width: 200px;
    opacity: 0;
    pointer-events: none;
    transition: 400ms ease-in-out;
}

.prduct-dropdown-inner{
    padding: 10px 0px;
    background: #fff;   
    box-shadow: 0 0 15px rgba(0, 0, 0, .2);
    border-radius: 12px;
}

.nav-container ul .product-li:hover .product-dropdown{
    opacity: 1;
    pointer-events: unset;
    top: 0px;
    transition: 400ms ease-in-out;
}

.nav-container ul .product-li .drop-icon{
    transition: 400ms ease-in-out;
}

.nav-container ul .product-li:hover .drop-icon{
    color: #FE5D14;
    transition: 400ms ease-in-out;
    transform: rotate(90deg);
}

.nav-container ul .product-li:hover a{
    color: #FE5D14;
    transition: 400ms ease-in-out;
}


.prduct-dropdown-inner h6{
    padding: 12px 20px;
    margin: 0px 0px;
    cursor: pointer;
    font-weight: 400;
}

.prduct-dropdown-inner h6 a{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}

.prduct-dropdown-inner h6 span{
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
    color: #0C1239;
    font-size: 13px;
    font-weight: 600;
    transition: 400ms ease-in-out;
}

.prduct-dropdown-inner h6 svg{
    color: #0C1239;
    font-size: 13px;
    font-weight: 600;
    transition: 400ms ease-in-out;
}

.sub-product-dropdown{
    position: absolute;
    top: 30px;
    right: -202px;
    padding: 25px 0px;
    width: 200px;
    opacity: 0;
    pointer-events: none;
    transition: 400ms ease-in-out;
}

.sub-prduct-dropdown-inner{
    padding: 10px 0px;
    background: #fff;   
    box-shadow: 0 0 15px rgba(0, 0, 0, .2);
    border-radius: 12px;
}

.roofing-dropdown:hover .product-dropdown-svg{
    color: #FE5D14;
    transform: rotate(90deg);
    transition: 400ms ease-in-out;
}

.roofing-dropdown:hover .product-dropdown-head{
    color: #FE5D14;
    transition: 400ms ease-in-out;
}

.roofing-dropdown:hover .sub-product-dropdown-roof{
    opacity: 1;
    pointer-events: unset;
    top: 15px;
    transition: 400ms ease-in-out;
}


.sub-product-dropdown-Accesories{
    top: 65px;
}

.Acessories-dropdown:hover .sub-product-dropdown-Accesories{
    opacity: 1;
    pointer-events: unset;
    top: 50px;
    transition: 400ms ease-in-out;
}

.Acessories-dropdown:hover .product-dropdown-svg{
    color: #FE5D14;
    transform: rotate(90deg);
    transition: 400ms ease-in-out;
}

.Acessories-dropdown:hover .product-dropdown-head{
    color: #FE5D14;
    transition: 400ms ease-in-out;
}

.sub-product-dropdow-rg{
    top: 105px;
}

.RollorGates-dropdown:hover .sub-product-dropdow-rg{
    opacity: 1;
    pointer-events: unset;
    top: 90px;
    transition: 400ms ease-in-out;
}

.RollorGates-dropdown:hover .product-dropdown-svg{
    color: #FE5D14;
    transform: rotate(90deg);
    transition: 400ms ease-in-out;
}

.RollorGates-dropdown:hover .product-dropdown-head{
    color: #FE5D14;
    transition: 400ms ease-in-out;
}

.sub-product-dropdow-gates{
    top: 140px;
}

.Gates-dropdown:hover .sub-product-dropdow-gates{
    opacity: 1;
    pointer-events: unset;
    top: 125px;
    transition: 400ms ease-in-out;
}

.Gates-dropdown:hover .product-dropdown-svg{
    color: #FE5D14;
    transform: rotate(90deg);
    transition: 400ms ease-in-out;
}

.Gates-dropdown:hover .product-dropdown-head{
    color: #FE5D14;
    transition: 400ms ease-in-out;
}

.sub-product-dropdow-steel{
    top: 170px;
}

.Steel-dropdown:hover .sub-product-dropdow-steel{
    opacity: 1;
    pointer-events: unset;
    top: 155px;
    transition: 400ms ease-in-out;
}

.Steel-dropdown:hover .product-dropdown-svg{
    color: #FE5D14;
    transform: rotate(90deg);
    transition: 400ms ease-in-out;
}

.Steel-dropdown:hover .product-dropdown-head{
    color: #FE5D14;
    transition: 400ms ease-in-out;
}

.sub-prduct-dropdown-inner h6:hover span{
    color: #FE5D14;
}

.sub-prduct-dropdown-inner h6:hover svg{
    color: #FE5D14;
}

