.preloader {
    height: 100vh;
    width: 100%;
    background: #131944;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    overflow: hidden;
    z-index: 100;
    overflow-y: hidden;
}

.texts-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    height: 60px;
    width: 100%;
    max-width: 97%;
    margin: auto;
    font-size: 55px;
    overflow: hidden;
    font-weight: 800;
    color: #fff;
    opacity: 0;

}

.ornage{
    color: #FE5D14;
}

.preloader .preloder-img{
    position: fixed;
    top: 17px;
    left: 15px;
    width: 120px;
    object-fit: contain;
    animation: animmation-img 300ms;
}

@media screen and (min-width:200px) and (max-width:700px){
    .texts-container {
        font-size: 25px;
    
    }
    
    .preloader .preloder-img{

        width: 80px;
    }
}

@media screen and (min-width:200px) and (max-width:310px){
    .texts-container {
        font-size: 22px;
    
    }
}

@keyframes animmation-img{
    0%{
        opacity: 0;
    }70%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}