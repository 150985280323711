

.fixed-icons-wtp .whatsapp{
	position:fixed;
	font-size:30px;
	width:60px;
	height:60px;
	bottom:24px;
	right:90px;
  background: #4FCE5D;
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #2f2f2f;
  z-index: 50;
}


.fixed-icons-wtp .whatsapp svg{
    position: absolute;
    top: 50%;
    left: 53%;
    transform: translate(-50% , -50%);
    color: #fff;
    font-size: 33px;
}




.fixed-icons-wtp .my-float{
	margin-top:12px;
}


/* .fixed-icons-wtp .whatsapp:before{
  position:absolute;
  content:'';
  background: #4FCE5D;
  left:0;
  right:0;
  bottom:0;
  top:0;
  border-radius:50%;
  animation:animate 2s linear infinite;
}
.fixed-icons-wtp .whatsapp:after{
  position:absolute;
  content:'';
  background: #4FCE5D;
  left:0;
  right:0;
  bottom:0;
  top:0;
  border-radius:50%;
  animation:animate 2s 1s linear infinite;
} */

/* @keyframes animate{
  100%{
    transform:scale(1.5);
    opacity:0
  }
} */