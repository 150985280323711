@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

h1 , h2 ,  h3 , h4 , h5 , h6 ,  p{
    font-family: "Barlow", sans-serif;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


html  , body {
  overflow-x: hidden;
}


@media (max-width:600px){
  .main{
    margin: 0 4%;
  }
  .curosr{
    display: none;
  }
  
}

.curosr{
  width: 10px;
  height: 10px;
  background:#FE5D14;
  position: absolute;
  border-radius: 50%;
  transform: translate(-50% , -50%);
  transition: linear ;
  pointer-events: none;
  z-index: 100;
}

 h1::selection , p::selection ,  h2::selection ,  h3::selection ,  h4::selection ,  h5::selection ,  h6::selection , span::selection{
  color: #fff;
  background: #FE5D14;
}
