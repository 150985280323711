.tob-bar-conatiner{
    width: 100%;
    background: #131944;
}

.tb-innercontainer{
    width: 95%;
    max-width: 1300px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.social-mdias{
    display: flex;
    justify-content: flex-start;
    gap: 12px;
}

.contact-option{
    display: flex;
    justify-content: flex-end;
    gap: 12px;
}

.soc{
    padding: 8px 15px;
    color: #fff;
    text-decoration: none;
}

.con{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 8px 15px;
    color: #fff;
    text-align: none;
    text-decoration: none;
    margin: 0;

}

.con span{
    margin: 0;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
    font-size: 14px;
}

.con svg{
    position: relative;
    top: 2px;
}

@media screen and (min-width:200px) and (max-width:750px){
    .tob-bar-conatiner{
        display: none;
    }
}