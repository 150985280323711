.conatiner{
    width: 100%;
    height: fit-content;
    position: relative;
    margin: 100px 0px;
}


.inner-contactus-container{
    display: flex;
    justify-content: center;
    gap: 2rem;
}

.form{
    flex-basis: 50%;
    height: 100%;
    background-image: url('../../assets/images/contact_form_bg.jpg');
    height: 100%;
    background-size: cover;
    background-position: center;
    border-radius: 4px;
}


.form .inner-form{
    padding: 20px 35PX;
}

.inner-form h3{
    font-size: 32px;
    font-family: 'Poppins', sans-serif;
    margin-bottom: -8px;
}

.inner-form p{
    color: #61657E;
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
}

.inner-form .input{
    width: 100%;
    background: #fff;
    border-radius: 4px;
}

.inner-form .input input{
    border: none;
    outline: none;
    background: transparent;
    padding: 12px 12px;
}

.inner-form .input input::placeholder , .form-container textarea::placeholder{
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    color: #61657E;
}

.form-container .fName-LName{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin: 20px 0px;
}

.form-container .email-number{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin: 20px 0px;
}

.form-container .subject{
    margin: 20px 0px;
}

.form-container textarea{
    width: 100%;
    border: none;
    outline: none;
    background: transparent;
}

.form-container .message{
    overflow: none;
    border-radius: 4px;
}

.form-btn{
    width: 100%;
    margin: 20px 0px;   
}

.map-details{
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.need-any-help{
    padding: 20px 35PX;
    background: #F4F6FF;
    border-radius: 4px;
}

.need-any-help h3{
    font-size: 32px;
    font-family: 'Poppins', sans-serif;
    margin-bottom: -8px;
}

.need-any-help p{
    color: #61657E;
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
}

.need-any-help .compnay-details{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top:25px ;
}

.need-any-help .compnay-details .detail-icon{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FE5D14;
    padding: 15px;
}

.need-any-help .compnay-details .detail-icon svg{
    color: #fff;
    font-size: 25px;
}

.detail p{
    font-size: 12px;
}

.detail p:first-child{
    margin: 8px 0px; 
}

.detail p:nth-child(1){
    margin: -8px 0px; 
}

 iframe{
    margin-top: 20px;
    height:auto;
    border-radius: 4px;
    flex-grow: 1;
}


@media screen and (min-width:200px) and (max-width:850px){
    .inner-contactus-container{
        flex-direction: column;
    }
    iframe{
        height: 300px;
    }
    
}


@media screen and (min-width:200px) and (max-width:600px){
    .need-any-help .compnay-details{
        flex-direction: column;
        gap: 2rem;
       
    }
    .need-any-help .compnay-details .detail{
        flex-direction: column;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
}

@media screen and (min-width:200px) and (max-width:450px){
    .form .inner-form{
        padding: 20px 10PX;
    }
}

@media screen and (min-width:200px) and (max-width:450px){
    .form .inner-form{
        padding: 20px 20PX;
    }
    .form-container .fName-LName{
        flex-direction: column;
    }
    
    .form-container .email-number{
       flex-direction: column;
    }
}

@media screen and (min-width:200px) and (max-width:350px){
    .inner-contactus-container h3{
        font-size: 25px;
    }
    
}