.our-services-conatiner{
    background-image: url("../../assets//images/services_bg.jpg");
    background-size: cover;
    background-position: center;
    height: fit-content;
    padding: 50px 0;
    overflow: hidden;
}

.our-services-innnercontainer{
    width: 95%;
    max-width: 1000px;
    margin: auto;
}

.roofing-main-container h2{
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-size: 34px;
}

.our-services-head{
    width: 100%;

}

.ser-hp{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    color: #FE5D14;
    font-family: 'Poppins', sans-serif;
    font-size: 17px;
}

.our-services-head h3{
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-size: 34px;
    margin:-7px 0px;
}

.ser-pp{
    color: #ccee;
    font-family: 'Poppins', sans-serif;
    width: 100%;
    max-width: 800px;
    font-size: 13px;
}

.Main-produt-button-container{
    width: 100%;
    max-width: 640px;
    margin: 30px 0px;
    grid-column-gap: 0.8rem;
    grid-row-gap: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(100px,1fr));
}

.Main-produt-button-container .button-main-prod{
    position: relative;
    border: none;
    outline: none;
    border-radius: 4px;
    padding: 8px 10px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    background: #FFF;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    color: #000;
    font-weight: 600;
    font-size: 13px;
    text-decoration: none;
}

.button-main-prod::before{
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0%;
    background: #FE5D14;
    transition: 0.8s;
    border-radius: 50% 50% 0% 0%;
}

.button-main-prod:hover::before{
    height:180%;
    transition:0.8s ;
    border-radius: 4px;
}

.button-main-prod:hover span{
    color: #fff;
    transition:0.8s ;
}


.button-main-prod span{
    z-index: 1;
    transition:0.8s ;
}

.button-main-prod.active{
    background: #FE5D14;
    color: #fff;
}

.options-conatiner{
    width: 100%;
    max-width: 700px;
    margin: 30px 0px;
    grid-column-gap: 0.8rem;
    grid-row-gap: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(100px,1fr));

}

.accessories-door-option-conatiner{
    max-width: 1000px;
    grid-template-columns: repeat(auto-fit,minmax(100px,1fr));
}

.rollor-door-option-conatiner{
    max-width: 300px;
    grid-template-columns: repeat(auto-fit,minmax(50px,1fr));
}

.options-conatiner .options{
    position: relative;
    width: 100%;
    padding: 20px 0px;
    background: #fff;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: 0.2s linear;
    cursor: pointer;
}


.options.active{
    transition: 0.2s linear;
    background: #FE5D14;
}

.options.active::before{
    content: "";
    position: absolute;
    left: 40%;
    transform: translateX(-40%) rotate(180deg);
    bottom: -12px;
    border-style: solid;
    border-width: 15px 13px 0px 13px;
    border-color: #FE5D14 transparent transparent;
    z-index: 3;
    transform: scaleX(1);
    transition: 0.2s linear;
}

.options.active h3{
    transition: 0.2s linear;
    color: #fff;
}

.options .option-h3{
    margin: 12px 0px -18px;
}

.One-H .option-h3{
    margin: 0px;
}

.options h3{
    font-size: 16px;
    transition: 0.2s linear;
    color: #000;
}

.options svg{
    color: #000;
    font-size: 45px;
    transition: 0.2s linear;
}

.options::before{
    content: "";
    position: absolute;
    left: 40%;
    transform: translateX(-40%) rotate(180deg);
    bottom: -12px;
    border-style: solid;
    border-width: 15px 13px 0px 13px;
    border-color: #fff transparent transparent;
    z-index: 3;
    transform: scaleX(0);
    transition: 0.2s linear;
}

.options:hover::before{
    transition: 0.5s linear;
    transform: scaleX(1);
    border-color: #FE5D14 transparent transparent;
    transition: 0.2s linear;
}

.options:hover{
    background: #FE5D14;
    transition: 0.2s linear;
}

.options:hover h3{
    transition: 0.2s linear;
    color: #fff;
}

.options:hover svg{
    transition: 0.2s linear;
    color: #fff;
    font-size: 45px;
}

.option-description{
    width: 100%;
    background: #fff;
    margin: 30px 0px 0px;
    border-radius: 12px;   
    height: fit-content;
}

.op-inner-container{
    padding: 15px;
    display: flex;
    justify-content: center;
    gap: 2rem;
}

.op-des-cont{
    flex-basis: 60%;
}

.op-des-cont h2{
    font-size: 24px;
    margin: 0px 0 20px;
    color: #0C1239;
}

.op-des-img{
    position: relative;
    flex-basis: 40%;
    border-radius: 12px;
    height: fit-content;
}


.op-des-img-container{
    position: relative;
    height: 400px;
}

.op-des-img-container img{
    position: relative;
    cursor: pointer;
}


.op-des-img .product-img{
    width: 100%;
    object-fit: contain;
    mix-blend-mode: multiply;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 12px;
    z-index: 2;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    animation:photo 0.5s linear;
}

@keyframes photo {
    0%{
        opacity: 0;
    }100%{
        opacity: 1;
    }
}

.op-des-img .product-logo{
    position: absolute;
    top: 12px;
    left: 12px;
    z-index: 2;
    width: 80px;
}

.options-image-select{
    width: 100%;
    margin: 15px 0px;
    display: flex;
    justify-content: flex-start;
    gap: 8px;
}

.options-image-select img{
    position: relative;
    overflow: hidden;
    width: 30px;
    height: 30px;
    border-radius: 12px;
    border:2px solid  #FE5D14;
    padding: 8px;
    cursor: pointer;
}

/* .options-image-select img::before{
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: red;
} */


.color-code{
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 8px 22px;
    font-weight: 600;
    font-size: 12px;
    border-radius: 22px;
    background: red;
    color: white;
    z-index: 5;
}

.op-des h3{
    margin: 0px 0px 10px;
    font-size: 20px;
    color: #0C1239;
    font-family: 'Poppins', sans-serif;
}


.op-des p{
    flex-basis: 50%;
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
    margin: 4px 0px;
}

.op-des svg{
    color: #FE5D14;
    box-shadow: 4px 4px 15px rgba(254, 93, 20, 0.4);

    border-radius: 50%;
 
}

.benifits{
    margin-top: 20px;
}


.thik{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;
}

.thik div{
    border: 1px solid #FE5D14;
    padding: 8px 22px;
    border-radius: 50px;
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
   
}

.product-btn{
    border-radius: 50px;
    margin-top: 20px;
}

@media screen and (min-width:200px) and (max-width:700px){
    .op-inner-container{
        flex-direction: column;
    }
}

@media screen and (min-width:200px) and (max-width:400px){
    .options h3{
        font-size: 14px;
    }
}

@media screen and (min-width:200px) and (max-width:400px){
    .roofing-main-container h2{
        font-size: 24px;
    }
}