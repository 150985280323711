.shadow{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #0000008b;
    z-index: 50;
    opacity: 0;
    cursor: pointer;
    pointer-events: none;
    transition: all linear 500ms;
}

.shadow-active{
    opacity: 1;
    pointer-events: unset;
    transition: all linear 500ms;
}