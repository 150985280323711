.noti-baar{
    position: fixed;
    z-index: 100;
    width: 100%;
    top: -100px;
}

.noti-inner-bar{
    width: 100%;
    max-width: 400px;
    background: #131944;
    margin: auto;
    border-radius: 22px;
}

.noti-inner-bar h4{
    text-align: center;
    color: #fff;
    padding: 10px 0px;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    display: flex;
    justify-content: center;
    gap: 6px;
    align-items: center;
}

.noti-inner-bar h4 svg{
    color: #FE5D14;
    font-size: 20px;
}

.noti-baar.active{
    animation: animateNoti ease-out 4s;
}
  
  @keyframes animateNoti {
    0%{
      top: -100px;
    }15%{
      top: 2px;
    }95%{
      top: 2px;
    }100%{
      top: -100px;;
    }
  }