.shadow-each-image{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #0000008b;
    z-index: 100;
    opacity: 0;
    cursor: pointer;
    pointer-events: none;
    transition: all linear 500ms;
}

.shadow-active-each-image{
    opacity: 1;
    pointer-events: unset;
    transition: all linear 500ms;
}

.image-each-container{
    width: 100%;
    max-width: 95%;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
}

.image-each-inner-container{
    position: relative;
    background: #fff;
    padding: 40px;
    border-radius: 12px;
    max-width: 1000px;
    height: 80vh;
    margin: auto;
}

.shadow-each-image .each-image-shadow{
    width: 100%;
    object-fit: contain;
    mix-blend-mode: multiply;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.shadow-each-image svg{
    position: fixed;
    height: 2rem;
    width: 2rem;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
    padding: 5px;
    color: #fff;
    cursor: pointer;
}

.each-logo-img{
    position: absolute;
    top: 12px;
    left:12px;
    width: 120px;
    object-fit: contain;
}

@media screen and (min-width:200px) and (max-width:1000px){
    .image-each-inner-container{
        height: 50vh;
    }
}

@media screen and (min-width:200px) and (max-width:500px){
    .image-each-inner-container{
        height: 40vh;
    }
    .shadow .each-image-shadow{
        object-fit: contain;
    }
}
