.banner-container{
    position: relative;
    position: relative;
    background-size: cover;
    background-position: center;
    z-index: 1;
    height: 90vh;
    margin: 0;
}

.banner-container::before {
    background: linear-gradient(90deg,rgba(0, 0, 0, 0.615) 39.52%,rgba(0, 0, 0, 0.281) 55.54%);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.banner-inner-container{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
}

.banner-inner-container .content{
    max-width: 1300px;
    width: 95%;
    margin: auto;
    font-family: 'Roboto', sans-serif;
}

.banner-inner-container .content h2{
    margin: 0;
    color: white;
    font-size: 65px;
    max-width: 850px;
    font-weight:600;

}

.banner-inner-container .content p{
    max-width: 550px;
    font-size: 20px;
    margin: 0;
    margin: 18px 0px;
    color: white;
    font-weight: 400;
}

.Arrow{
    position: absolute;
    color: #FE5D14;
    font-size: 25px;
    margin: 0;
    padding: 0;
    background: rgba(255, 255, 255, 0.2);
    display: inline-block;
    cursor: pointer;
    height: 55px;
    width: 55px;
    line-height: 55px;
    border-radius: 50px;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    transition: linear 0.2s;
}

.Arrow svg{
    font-weight: 800;
    color: #FFFFFF;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
}

.prev-Arrow{
    left: 1%;
    z-index: 1;
}

.next-Arrow{
    right: 1%;
}

.Arrow:hover{
    background: #FFFFFF;
    transition: linear 0.2s;
}

.Arrow:hover svg{
    color: #FE5D14;
    transition: linear 0.2s;
}

@media screen and (min-width:200px) and (max-width:1120px){
    .Arrow{
        top: 5%;
        transform: translateY(0%);
    }
    .Arrow{
        font-size: 20px;
        height: 40px;
        width: 40px;
    }
    .prev-Arrow{
        left: 85%;
        z-index: 1;
    }
    
    .next-Arrow{
        right: 5%;
    }       
}

@media screen and (min-width:200px) and (max-width:800px){
    .prev-Arrow{
        left: 82%;
        z-index: 1;
    }

}

@media screen and (min-width:200px) and (max-width:650px){
    .prev-Arrow{
        left: 79%;
        z-index: 1;
    }
    .banner-inner-container .content h2{
        font-size: 45px;
    }
}

@media screen and (min-width:200px) and (max-width:550px){
    .prev-Arrow{
        left: 75%;
        z-index: 1;
    }
    .banner-inner-container .content h2{
        font-size: 40px;
    }
}

@media screen and (min-width:200px) and (max-width:450px){
    .prev-Arrow{
        left: 73%;
        z-index: 1;
    }
    .banner-inner-container .content h2{
        font-size: 35px;
    }
}

@media screen and (min-width:200px) and (max-width:400px){
    .prev-Arrow{
        left: 65%;
        z-index: 1;
    }
    .banner-inner-container .content h2{
        font-size: 30px;
    }
    .banner-inner-container .content p{
        font-size: 13px;
    }
}

@media screen and (min-width:200px) and (max-width:350px){

    .banner-inner-container .content h2{
        font-size: 26px;
    }
}

@media screen and (min-width:200px) and (max-width:300px){
    .prev-Arrow{
        left: 62%;
        z-index: 1;
    }
}

